<template>
  <div class="app-container tem-edit">
    <div class="outer">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="110px"
        class="demo-ruleForm"
      >
        <div class="component">
          <h1 class="title">1、模板基础</h1>
          <el-form-item label="模板名称：" prop="name">
            <el-input
              v-model="ruleForm.name"
              class="width360"
              size="mini"
              maxlength="20"
              @keydown.enter.stop
              @key.enter.stop
              show-word-limit
              placeholder="请输入模板名称"
            ></el-input>
            <input style="display: none" type="text">
          </el-form-item>
        </div>
        <div class="component">
          <h1 class="title">2、计费方式</h1>
          <el-form-item required label="计费方式：">
            <el-radio-group
              @change="changeBillType"
              v-model="ruleForm.billType"
              :disabled="query.type === 'edit'"
            >
              <el-radio :label="0"> 包邮 </el-radio>
              <el-radio :label="1"> 按件计费 </el-radio>
              <!-- <el-radio :label="2">按重量计费</el-radio> -->
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-if="ruleForm.billType === 0" class="component">
          <h1 class="title">3、包邮方式</h1>
          <el-form-item label="包邮省份：" prop="areaData">
            <div class="area-tag-box">
              <el-tag
                v-for="(item, index) in ruleForm.areaList"
                @close="removeCity(ruleForm.areaData, item, index)"
                class="tags"
                closable
                :key="index"
                size="mini"
                >{{ item.fullName }}</el-tag
              >
            </div>
            <!-- <el-cascader
              ref="cascader"
              v-model="ruleForm.areaData"
              :emitPath="false"
              popper-class="freight-cascader-multiple-cj1"
              class="width360"
              :props="{
                multiple: true,
                lazy: true,
                checkStrictly: true,
                emitPath: false,
                value: 'regionCode',
                label: 'regionName',
                lazyLoad: loadAddr,
              }"
              clearable
              size="mini"
              @change="changeArea"
            >
              <template slot-scope="{ node, data }">
                <div class="flex flex-m">
                  <el-checkbox
                    @click.native.stop
                    @change="addrOptionChange($event, node, 'cascader')"
                    :indeterminate="node.indeterminate"
                    :disabled="node.isDisabled"
                    class="other-checkbox"
                    v-model="node.checked"
                  ></el-checkbox>
                  <span class="other-nodetext flex-1">{{ node.label }}</span>
                </div>
              </template>
            </el-cascader> -->
            <!-- <el-button
              style="margin-left: 10px"
              size="mini"
              @click="setAllArea()"
            >
              全选
            </el-button> -->
            <el-button
              style="margin-left: 10px; vertical-align: top"
              size="mini"
              @click="showCitySelectModal = true"
            >
              编辑
            </el-button>
          </el-form-item>
        </div>
      </el-form>

      <div v-if="ruleForm.billType !== 0" class="component">
        <h1 class="title">3、计费规则</h1>
        <div>
          <el-button
            type="primary margin_bottom_10"
            size="mini"
            @click="operation('add')"
          >
            添加一条
          </el-button>
        </div>
        <el-table
          :data="tradeBillCommonDtoList"
          border
          style="width: 100%"
          size="small"
          :header-cell-style="{
            background: '#d9dde1',
            color: '#0d0202',
          }"
        >
          <el-table-column align="center" label="可配送区域" width="400">
            <template slot-scope="{ row }">
              <div style="display: flex; align-items: center">
                <!-- <el-cascader
                  v-model="scope.row.areaList"
                  popper-class="freight-cascader-multiple-cj1"
                  style="width: 100%"
                  :options="options"
                  :key="scope.$index"
                  :ref="'tbCascader' + scope.$index"
                  @change="
                    changeTbAreas(scope.row, 'tbCascader' + scope.$index)
                  "
                  :props="{
                    multiple: true,
                    lazy: true,
                    checkStrictly: true,
                    emitPath: false,
                    value: 'regionCode',
                    label: 'regionName',
                    lazyLoad: loadAddr,
                  }"
                  clearable
                  size="mini"
                >
                  <template slot-scope="{ node, data }">
                    <div class="flex flex-m">
                      <el-checkbox
                        @click.native.stop
                        @change="
                          addrOptionChange(
                            $event,
                            node,
                            'tbCascader' + scope.$index
                          )
                        "
                        :indeterminate="node.indeterminate"
                        :disabled="node.isDisabled"
                        class="other-checkbox"
                        v-model="node.checked"
                      ></el-checkbox>
                      <span class="other-nodetext flex-1">{{
                        node.label
                      }}</span>
                    </div>
                  </template>
                </el-cascader> -->
                <div class="area-tag-box" style="min-height: 100">
                  <el-tag
                    v-for="(item, index) in row.areaList"
                    @close="removeCity(row.area, item, index, row)"
                    class="tags"
                    closable
                    :key="index"
                    size="mini"
                    >{{ item.fullName }}</el-tag
                  >
                </div>
                <el-button
                  style="margin-left: 10px; height: 30px"
                  size="mini"
                  @click="editTbArea(row)"
                >
                  编辑
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="ruleForm.billType === 1 ? '首件数（件）' : '首重（kg）'"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.firstNum"
                style="width: 100%"
                placeholder="请输入"
                size="mini"
                :min="0"
                :max="999999"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="首费" width="150">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.firstFee"
                style="width: 100%"
                placeholder="请输入"
                size="mini"
                :min="0"
                :max="999999"
              />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="
              ruleForm.billType === 1 ? '续费件数（件）' : '续费重量（kg）'
            "
            width="150"
          >
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.renewNum"
                :disabled="ruleForm.billType === 2"
                style="width: 100%"
                placeholder="请输入"
                size="mini"
                :min="0"
                :max="999999"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="续费金额" width="150">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.renewFee"
                :disabled="ruleForm.billType === 2"
                style="width: 100%"
                placeholder="请输入"
                size="mini"
                :min="0"
                :max="999999"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否条件包邮" width="100">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.isShip"
                placeholder="请选择"
                size="mini"
              >
                <el-option label="是" :value="1"> </el-option>
                <el-option label="否" :value="0"> </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column align="center" label="包邮条件" min-width="270">
            <template v-if="scope.row.isShip === 1" slot-scope="scope">
              <div style="display: flex">
                满
                <el-input-number
                  v-model="scope.row.shipCondition"
                  style="width: 130px"
                  placeholder="请输入"
                  size="mini"
                  :min="0"
                  :max="999999"
                />
                <el-select
                  v-model="scope.row.isFee"
                  style="width: 70px"
                  size="mini"
                >
                  <el-option
                    :label="ruleForm.billType == 1 ? '件' : 'kg'"
                    :value="0"
                  >
                  </el-option>
                  <el-option :label="'元'" :value="1"> </el-option>
                </el-select>
                <div style="min-width: 30px">包邮</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="90">
            <template slot-scope="scope">
              <el-link
                class="margin_right_10"
                type="danger"
                @click="operation('delete', scope.row, scope.$index)"
              >
                删除
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-button type="primary" size="small" @click="submitForm">
          提 交
        </el-button>
        <el-button size="small" @click="toBack()"> 返回 </el-button>
      </div>
    </div>

    <!-- 提交确认弹框 -->
    <el-dialog title="提示" :visible.sync="showConfirmSubmit" width="420px">
      <span style="font-size: 14px">是否确认提交?</span>
      <span slot="footer">
        <el-button @click="showConfirmSubmit = false">取 消</el-button>
        <el-button :loading="confirmLoad" type="primary" @click="doSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 城市多选弹框 -->
    <CitySelectModal
      :cityList="defaultCityList"
      @confirm="confirmArea"
      v-model="showCitySelectModal"
    >
    </CitySelectModal>
  </div>
</template>

<script>
import "./style.scss";
import CitySelectModal from "./components/CitySelectModal.vue";
export default {
  components: {
    CitySelectModal,
  },
  data() {
    return {
      showConfirmSubmit: false,
      confirmLoad: false,
      defaultCityList: [],
      currentTbRow: {},
      showCitySelectModal: false,
      query: {},
      tradeBillCommonDtoList: [{ area: {} }],
      deleteList: [],
      options: [],
      areaData: [],
      ruleForm: {
        areaList: [],
        name: "",
        billType: 0,
        areaData: null,
      },
      rules: {
        name: [
          { required: true, message: "请输入模板名称", trigger: "change" },
        ],
        areaData: [
          { required: true, message: "请选择包邮地区", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    "ruleForm.billType"(item) {
      if (item == 2) {
        this.tradeBillCommonDtoList.forEach((item) => {
          this.$set(item, "renewNum", 0);
          this.$set(item, "renewFee", 0);
        });
      }
    },
  },
  mounted() {
    this.query = this.$route.query;
    // this.getAreaData()
    if (this.query.type === "edit") {
      this.gitData();
    }
  },
  methods: {
    editTbArea(item) {
      console.log(item.areaList)
      this.currentTbRow = item;
      this.defaultCityList = item.areaList;
      this.showCitySelectModal = true;
    },
    confirmArea(data) {
      const { fullList, list } = data;
      if (this.ruleForm.billType === 0) {
        //包邮
        this.ruleForm.areaData = list;
        if (this.ruleForm.areaList) {
          this.ruleForm.areaList = fullList;
        } else {
          this.$set(this.ruleForm, "areaList", fullList);
        }
      } else {
        this.currentTbRow.area = list;
        if (this.currentTbRow.areaList) {
          this.currentTbRow.areaList = fullList;
        } else {
          this.$set(this.currentTbRow, "areaList", fullList);
        }
      }
      console.log(this.ruleForm, list);
    },
    initDefaultCity(arr, areaData) {
      if (Array.isArray(areaData)) {
        areaData.forEach((e) => {
          let objKey = Object.keys(e)[0];
          if (e[objKey].length) {
            this.initDefaultCity(arr, e[objKey]);
          } else {
            arr.push(objKey);
          }
        });
      } else {
        Object.keys(areaData).forEach((key) => {
          if (areaData[key].length) {
            this.initDefaultCity(arr, areaData[key]);
          } else {
            arr.push(key);
          }
        });
      }
    },
    removeCity(list, item, index, fullList) {
      if (item.regionCode.length <= 4) {
        Object.keys(list).forEach((key) => {
          //省
          if (key == item.regionCode) {
            delete list[key];
          } else {
            if (key[0] == item.regionCode[0] && key[1] == item.regionCode[1]) {
              if (list[key].length <= 1) {
                delete list[key];
              } else {
                let filterIndex = list[key].findIndex((e) => {
                  let cityKey = Object.keys(e)[0];
                  if (cityKey == item.regionCode) {
                    return true;
                  }
                });

                list[key].splice(filterIndex, 1);
              }
            }
          }
        });
      }

      if (item.regionCode.length == 6) {
        //区
        Object.keys(list).forEach((key) => {
          //省

          if (key[0] == item.regionCode[0] && key[1] == item.regionCode[1]) {
            let cityLength = list[key].length;

            list[key].forEach((e, i) => {
              //市
              let ctyKey = Object.keys(e)[0];
              let countryLen = e[ctyKey].length;

              let filterIndex = e[ctyKey].findIndex((se) => {
                let cKey = Object.keys(se)[0];
                if (cKey == item.regionCode) {
                  return true;
                }
              });

              if (filterIndex >= 0) {
                if (countryLen > 1) {
                  e[ctyKey].splice(filterIndex, 1);
                } else {
                  if (cityLength > 1) {
                    list[key].splice(i, 1);
                  } else {
                    delete list[key];
                  }
                }
              }
            });
          }
        });
      }

      if (item.regionCode.length > 6) {
        //街道
        Object.keys(list).forEach((key) => {
          //省

          if (key[0] == item.regionCode[0] && key[1] == item.regionCode[1]) {
            let cityLength = list[key].length;

            list[key].forEach((e, i) => {
              //市
              let ctyKey = Object.keys(e)[0];
              let countryLen = e[ctyKey].length;

              e[ctyKey].forEach((c, ci) => {
                
                //区
                let qKey = Object.keys(c)[0];
                let jLen = c[qKey].length;

                let filterIndex = c[qKey].findIndex((s) => {
                  let skey = Object.keys(s)[0];
                  if (skey == item.regionCode) {
                    return true;
                  }
                });

                if (filterIndex >= 0) {
                  if (jLen > 1) {
                    c[qKey].splice(filterIndex, 1);
                  } else {
                    if (countryLen > 1) {
                      e[ctyKey].splice(ci, 1);
                    } else {
                      if (cityLength > 1) {
                        list[key].splice(i, 1);
                      } else {
                        delete list[key];
                      }
                    }
                  }
                }
              });
            });
          }
        });
      }

      if (this.ruleForm.billType == 0) {
        this.ruleForm.areaList.splice(index, 1);
        this.defaultCityList = this.ruleForm.areaList;
      } else {
        fullList.areaList.splice(index, 1);
      }

      console.log(fullList.areaList, fullList.area)
    },
    gitData() {
      this.$axios
        .get(this.$api.trade_template_mail.detail, {
          params: {
            templateType: this.query.templateType,
            templateId: this.query.id,
            // billId: this.query.billId
          },
        })
        .then((res) => {
          if (res.code === 0) {
            //包邮
            this.ruleForm = {
              ...res.data,
              billType: res.data.tradeBillCommonDtoList
                ? res.data.tradeBillCommonDtoList[0].billType
                : 0,
            };
            if (res.data.tradeBillCommonDtoList[0].billType === 0) {
              this.$set(
                this.ruleForm,
                "areaData",
                JSON.parse(res.data.tradeBillCommonDtoList[0].area || "{}")
              );

              this.$set(
                this.ruleForm,
                "areaList",
                res.data.tradeBillCommonDtoList[0].areaList || "[]"
              );

              this.defaultCityList =
                res.data.tradeBillCommonDtoList[0].areaList;
            } else {
              res.data.tradeBillCommonDtoList.forEach((item) => {
                item.area = JSON.parse(item.area || "{}");
                item.areaList = item.areaList;
              });
              this.tradeBillCommonDtoList = res.data.tradeBillCommonDtoList;
            }
          }
        });
    },
    loadAddr(node, resolve) {
      let code = 0;

      if (!node.root) {
        code = node.value;
      }

      if (node.label === "全部") {
        resolve([]);
        return;
      }

      this.getAreaData(code).then((res) => {
        if (res.code === 0 && res.data.length) {
          res.data.unshift({
            regionCode: `${node.level}-${node.value}`,
            regionName: "全部",
            cancelMark: false,
          });
          resolve(res.data);
        } else {
          resolve([]);
        }
      });
    },
    addrOptionChange(checked, node, name) {
      if (node.value.includes("-")) {
        let allNodes;
        if (node.parent) {
          allNodes = node.parent.children;
        } else {
          allNodes = this.$refs[name].panel.getFlattedNodes();
          allNodes = allNodes.filter((e) => e.level == node.level);
        }

        allNodes.forEach((e) => e.doCheck(checked));
      }
      node.doCheck(checked);

      this.$refs[name].panel.calculateMultiCheckedValue();
    },
    changeTbAreas(row, name) {
      let allNodes = this.$refs[name].getCheckedNodes();
      allNodes = allNodes.filter((e) => !e.value.includes("-"));
      let areaMap = this.formatArea(allNodes);
      row.area = JSON.stringify(areaMap);
      // console.log(111, row, name, areaMap)
    },
    changeArea(item) {
      // if (checkItem.length) {
      //   let allNodes = this.$refs.cascader.panel.getFlattedNodes();
      //   // console.log(222, allNodes)
      //   // allNodes.forEach(e => e.setCheckState(true))
      // }
      // console.log();
      // this.$refs.cascader.validateField('areaData')
      // console.log(this.ruleForm.areaData, "ruleForm.areaData");
      // console.log(item, "change2222");
    },
    getAreaData(code) {
      return this.$axios.get(this.$api.commonNew.getChildRegion, {
        params: {
          regionCode: code,
        },
      });
    },
    formatArea(allNodes, level = 1) {
      let data;
      if (level == 1) {
        data = {};
      } else {
        data = [];
      }

      allNodes.forEach((e) => {
        if (e.checked) {
          if (level == 1) {
            data[e.value] = this.formatArea(e.children, e.level + 1);
          } else {
            data.push({
              [e.value]: this.formatArea(e.children, e.level + 1),
            });
          }
        }
      });
      return data;
    },
    getAreaParams() {
      let allNodes = this.$refs.cascader.getCheckedNodes();
      allNodes = allNodes.filter((e) => !e.value.includes("-"));
      let areaMap = this.formatArea(allNodes);

      return areaMap;
    },
    doSubmit() {
      this.confirmLoad = true;
      setTimeout(() => {
        let newArray = [...this.tradeBillCommonDtoList, ...this.deleteList];
        if (this.ruleForm.billType == 0) {
        } else {
          if (newArray.length > 0) {
            newArray.forEach((item) => {
              this.$set(item, "area", JSON.stringify(item.area));
              this.$set(item, "billType", this.ruleForm.billType);
              this.$set(item, "name", this.ruleForm.name);
              this.$set(item, "templateId", this.query.id);
              this.$set(item, "areaFullPath", JSON.stringify(item.areaList));
              item.areaList = null;
            });
          } else {
            this.$message({
              type: "warning",
              message: "请添加计费规则",
            });
            return;
          }
        }

        let arr = {};

        if (
          this.ruleForm.tradeBillCommonDtoList &&
          this.ruleForm.tradeBillCommonDtoList[0]
        ) {
          arr = { ...this.ruleForm.tradeBillCommonDtoList[0] };
        }

        let params = {
          ...this.ruleForm,
          templateType: 1, // 普通1 同城2
          areaList: null,
          tradeBillCommonDtoList:
            this.ruleForm.billType == 0
              ? [
                  {
                    ...arr,
                    area: JSON.stringify(this.ruleForm.areaData),
                    areaFullPath: JSON.stringify(this.ruleForm.areaList),
                    billType: 0,
                    name: this.ruleForm.name,
                    areaList: null,
                  },
                ]
              : newArray,
          storeId: this.$route.query.storeId ? this.$route.query.storeId : "",
        };

        let apiurl = this.$api.trade_template_mail.add;
        if (this.query.type === "edit") {
          apiurl = this.$api.trade_template_mail.edit;
        }

        this.$axios.post(apiurl, params).then((res) => {
          this.confirmLoad = false;

          if (res.code === 0) {
            this.$message({ type: "success", message: "操作成功！" });
            this.$router.push({
              path: "/nb/goods/freight",
              query: {
                freightType: 1,
              },
            });
          }
        });
      }, 0);
    },
    submitForm() {
      if (this.ruleForm.billType === 0) {
        if (!Object.keys(this.ruleForm.areaData).length) {
          this.$message({ type: "warning", message: "请选择区域" });
          return;
        }
      }

      let newArray = [...this.tradeBillCommonDtoList, ...this.deleteList];

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.billType !== 0) {
            for (let row of newArray) {
              if (
                !row.area ||
                Object.keys(row.area).length === 0 ||
                (!row.firstNum && row.firstNum !== 0) ||
                (!row.firstFee && row.firstFee !== 0) ||
                (!row.renewNum && row.renewNum !== 0) ||
                (!row.renewFee && row.renewFee !== 0) ||
                (row.isShip !== 1 && row.isShip !== 0) ||
                (row.isShip === 1 &&
                  !row.shipCondition &&
                  row.shipCondition !== 0) ||
                (row.isShip === 1 && row.isFee !== 0 && row.isFee !== 1)
              ) {
                this.$message({ type: "warning", message: "请完善价格表" });
                return;
              }
            }
          }

          this.showConfirmSubmit = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    operation(type, row, index) {
      if (type === "add") {
        this.tradeBillCommonDtoList.push({ area: {}, areaList: [] });
      } else if (type === "delete") {
        this.$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.tradeBillCommonDtoList.length <= 1) {
              this.$message({ type: "error", message: "至少添加一条方式" });
            } else {
              this.tradeBillCommonDtoList.splice(index, 1);
              if (row.id) {
                this.deleteList.push({ ...row, isDelete: 1 });
              }
            }
          })
          .catch(() => {});
      }
    },
    changeBillType() {
      this.defaultCityList = [];
      this.ruleForm.areaList = [];
    },
    toBack() {
      if (this.query.type === "show") {
        this.$router.go(-1);
        return;
      }
      this.$confirm("确定返回列表吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    setAllArea() {
      this.ruleForm.areaData = this.options.map((item) => item.regionCode);
    },
    setAllAreaTable(row) {
      this.$set(
        row,
        "areaList",
        this.options.map((item) => item.regionCode)
      );
    },
  },
};
</script>

<style lang='scss' scoped>
.tem-edit {
  .area-tag-box {
    width: 460px;
    min-height: 160px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    line-height: 2;
    .tags {
      margin-right: 10px;
    }
  }
}
</style>

<style lang="scss">
.el-cascader-menu__wrap {
  height: 300px;
}
</style>

<style>
.freight-cascader-multiple-cj1 .el-cascader-menu .el-checkbox {
  display: none;
}

.freight-cascader-multiple-cj1 .el-cascader-menu .other-checkbox {
  display: inline-block;
}

.freight-cascader-multiple-cj1 .el-cascader-menu .other-nodetext {
  line-height: 35px;
  padding: 0 10px;
}
</style>