var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container tem-edit" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "component" },
                [
                  _c("h1", { staticClass: "title" }, [_vm._v("1、模板基础")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "width360",
                        attrs: {
                          size: "mini",
                          maxlength: "20",
                          "show-word-limit": "",
                          placeholder: "请输入模板名称",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.stopPropagation()
                          },
                          key: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                      _c("input", {
                        staticStyle: { display: "none" },
                        attrs: { type: "text" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "component" },
                [
                  _c("h1", { staticClass: "title" }, [_vm._v("2、计费方式")]),
                  _c(
                    "el-form-item",
                    { attrs: { required: "", label: "计费方式：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.query.type === "edit" },
                          on: { change: _vm.changeBillType },
                          model: {
                            value: _vm.ruleForm.billType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "billType", $$v)
                            },
                            expression: "ruleForm.billType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v(" 包邮 "),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 按件计费 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.ruleForm.billType === 0
                ? _c(
                    "div",
                    { staticClass: "component" },
                    [
                      _c("h1", { staticClass: "title" }, [
                        _vm._v("3、包邮方式"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "包邮省份：", prop: "areaData" } },
                        [
                          _c(
                            "div",
                            { staticClass: "area-tag-box" },
                            _vm._l(
                              _vm.ruleForm.areaList,
                              function (item, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticClass: "tags",
                                    attrs: { closable: "", size: "mini" },
                                    on: {
                                      close: function ($event) {
                                        return _vm.removeCity(
                                          _vm.ruleForm.areaData,
                                          item,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.fullName))]
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "10px",
                                "vertical-align": "top",
                              },
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.showCitySelectModal = true
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm.ruleForm.billType !== 0
            ? _c(
                "div",
                { staticClass: "component" },
                [
                  _c("h1", { staticClass: "title" }, [_vm._v("3、计费规则")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary margin_bottom_10",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.operation("add")
                            },
                          },
                        },
                        [_vm._v(" 添加一条 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tradeBillCommonDtoList,
                        border: "",
                        size: "small",
                        "header-cell-style": {
                          background: "#d9dde1",
                          color: "#0d0202",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "可配送区域",
                          width: "400",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "area-tag-box",
                                          staticStyle: { "min-height": "100" },
                                        },
                                        _vm._l(
                                          row.areaList,
                                          function (item, index) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: index,
                                                staticClass: "tags",
                                                attrs: {
                                                  closable: "",
                                                  size: "mini",
                                                },
                                                on: {
                                                  close: function ($event) {
                                                    return _vm.removeCity(
                                                      row.area,
                                                      item,
                                                      index,
                                                      row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(item.fullName))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            height: "30px",
                                          },
                                          attrs: { size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editTbArea(row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 编辑 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1576074427
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label:
                            _vm.ruleForm.billType === 1
                              ? "首件数（件）"
                              : "首重（kg）",
                          width: "150",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请输入",
                                      size: "mini",
                                      min: 0,
                                      max: 999999,
                                    },
                                    model: {
                                      value: scope.row.firstNum,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "firstNum", $$v)
                                      },
                                      expression: "scope.row.firstNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2833251466
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "首费", width: "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请输入",
                                      size: "mini",
                                      min: 0,
                                      max: 999999,
                                    },
                                    model: {
                                      value: scope.row.firstFee,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "firstFee", $$v)
                                      },
                                      expression: "scope.row.firstFee",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          500869274
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label:
                            _vm.ruleForm.billType === 1
                              ? "续费件数（件）"
                              : "续费重量（kg）",
                          width: "150",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: _vm.ruleForm.billType === 2,
                                      placeholder: "请输入",
                                      size: "mini",
                                      min: 0,
                                      max: 999999,
                                    },
                                    model: {
                                      value: scope.row.renewNum,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "renewNum", $$v)
                                      },
                                      expression: "scope.row.renewNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          904424503
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "续费金额",
                          width: "150",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: _vm.ruleForm.billType === 2,
                                      placeholder: "请输入",
                                      size: "mini",
                                      min: 0,
                                      max: 999999,
                                    },
                                    model: {
                                      value: scope.row.renewFee,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "renewFee", $$v)
                                      },
                                      expression: "scope.row.renewFee",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2791975463
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "是否条件包邮",
                          width: "100",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "mini",
                                      },
                                      model: {
                                        value: scope.row.isShip,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "isShip", $$v)
                                        },
                                        expression: "scope.row.isShip",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "是", value: 1 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "否", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1068599762
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "包邮条件",
                          "min-width": "270",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.row.isShip === 1
                                  ? [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _vm._v(" 满 "),
                                          _c("el-input-number", {
                                            staticStyle: { width: "130px" },
                                            attrs: {
                                              placeholder: "请输入",
                                              size: "mini",
                                              min: 0,
                                              max: 999999,
                                            },
                                            model: {
                                              value: scope.row.shipCondition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "shipCondition",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.shipCondition",
                                            },
                                          }),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "70px" },
                                              attrs: { size: "mini" },
                                              model: {
                                                value: scope.row.isFee,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "isFee",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.isFee",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label:
                                                    _vm.ruleForm.billType == 1
                                                      ? "件"
                                                      : "kg",
                                                  value: 0,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "元",
                                                  value: 1,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "min-width": "30px",
                                              },
                                            },
                                            [_vm._v("包邮")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作", width: "90" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      staticClass: "margin_right_10",
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.operation(
                                            "delete",
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2398341352
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(" 提 交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.toBack()
                    },
                  },
                },
                [_vm._v(" 返回 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.showConfirmSubmit,
            width: "420px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showConfirmSubmit = $event
            },
          },
        },
        [
          _c("span", { staticStyle: { "font-size": "14px" } }, [
            _vm._v("是否确认提交?"),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showConfirmSubmit = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.confirmLoad, type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("CitySelectModal", {
        attrs: { cityList: _vm.defaultCityList },
        on: { confirm: _vm.confirmArea },
        model: {
          value: _vm.showCitySelectModal,
          callback: function ($$v) {
            _vm.showCitySelectModal = $$v
          },
          expression: "showCitySelectModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }